import React, { useState } from 'react';
import Map from './Map'; // Import the Map component

const Weather = () => {
  const [city, setCity] = useState('');
  const [weather, setWeather] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState(null); // Add position state

  const API_KEY = '8563cb7fedd42802f815a81a24045385'; // Replace with your actual API key

  // Fetch weather by city name
  const getWeatherByCity = async () => {
    if (!city) return;
    setLoading(true);
    setError('');
    try {
      const response = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?q=${city}&units=metric&appid=${API_KEY}`
      );
      const data = await response.json();
      if (data.cod === '404') {
        setError('City not found');
        setWeather(null);
      } else {
        setWeather(data);
      }
    } catch (err) {
      setError('Error fetching data');
    }
    setLoading(false);
  };

  // Fetch weather by geolocation
  const getWeatherByLocation = () => {
    setLoading(true);
    setError('');
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        setPosition([latitude, longitude]); // Set the position state
        try {
          const response = await fetch(
            `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=metric&appid=${API_KEY}`
          );
          const data = await response.json();
          setWeather(data);
        } catch (err) {
          setError('Error fetching data');
        }
        setLoading(false);
      },
      (error) => {
        setError('Geolocation not supported or permission denied');
        setLoading(false);
      }
    );
  };

  return (
    <div>
      <h1>Weather App</h1>
      <input
        type="text"
        placeholder="Enter city name"
        value={city}
        onChange={(e) => setCity(e.target.value)}
      />
      <button onClick={getWeatherByCity}>Get Weather by City</button>
      <button onClick={getWeatherByLocation}>Get Weather by Location</button>

      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {weather && weather.main && (
        <div>
          <h2>{weather.name}</h2>
          <p>Temperature: {weather.main.temp}°C</p>
          <p>Condition: {weather.weather[0].description}</p>
        </div>
      )}

      {position && <Map position={position} />} {/* Render the Map if position is available */}
    </div>
  );
};

export default Weather;
